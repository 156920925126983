import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Checkbox,
  Input,
  InputAdornment,
  Modal,
  Typography
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import api from "services/api";
import hp from "services/hp";
import MaterialTable from "material-table";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import Select from "react-select";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExportInvestments from "./components/ExportInvestments";
import DoneIcon from '@material-ui/icons/Done';
import RemoveIcon from '@material-ui/icons/Remove';

class Page extends Component {
  constructor() {
    super();
    this.state = {
      exportPopup: false,
      users: [],
      campaigns: [],
      is_refresh: false,
      isPaid: _isPaid === "" ? null : _isPaid,
      sentContract: _sentContract === "" ? null : _sentContract,
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.onStart();
    this.getRoleAdmin();
  }

  onStart = async () => {
    let { is_refresh, users, campaigns } = this.state;
    if (is_refresh) {
      hp.removeUsers();
      hp.removeCampaigns();
    }
    if (hp.getUsers() && hp.getCampaigns()) {
      users = hp.getUsers();
      campaigns = hp.getCampaigns();
    } else {
      var _users = await hp.get_users();
      campaigns = await hp.get_campaigns();
      _users.forEach((element) => {
        users.push({
          id: element.id,
          name: element.firstname + " " + element.lastname,
        });
      });
      hp.saveUsers(users);
      hp.saveCampaigns(campaigns);
    }
    this.setState({ users, campaigns, is_refresh: false });
  };

  //todo start role user admin
  getRoleAdmin = async () => {
    const admin_res = await api.get(`admin_profile`);
    const admin = admin_res.data;
    if (admin.role === "super admin") {
      this.setState({ isSuperAdmin: true });
    }
  };
  //todo end role user admin

  isNotSuperAdmin() {
    alert("Sorry, you do not have access to view this page.");
  }

  onInputChange = async (keyword, type) => {
    if (keyword) {
      if (type === "user" && keyword.length > 2) {
        const response = await api.get(`user/search?name=${keyword}`);
        const items = response.data;
        let options = items.map((item) => {
          return {
            value: item.id,
            // label: item.firstname + " " + item.lastname,
            label:
              item.firstname + " " + item.lastname + " (" + item.ic_name + ")",
          };
        });
        userOptions = options;
      } else if (type === "campaign") {
        const response = await api.get(`campaign/search?name=${keyword}`);
        const items = response.data;
        let options = items.map((item) => {
          return { value: item.id, label: item.name };
        });
        campaignOptions = options;
      }
    } else {
      userOptions = [];
      campaignOptions = [];
    }
  };

  onItemSelectChange = (options, props, f) => {
    var checked = "";
    switch (f) {
      case "user":
        options = options ? options : [];
        userSelectValues = options;
        break;

      case "campaign":
        options = options ? options : [];
        campaignSelectValues = options;
        break;

      case "payment_method":
        options = options ? options : [];
        paymentMethodSelectValues = options;
        break;

      case "amount":
        _amount = options = options.target.value;
        break;

      case "is_paid":
        if (this.state.isPaid === "" || this.state.isPaid === null) {
          checked = true;
        } else if (this.state.isPaid === true) {
          checked = false;
        }
        _isPaid = checked;
        options = _isPaid === "" ? null : _isPaid;
        this.setState({ isPaid: options });
        break;
      
      case "is_paid_2":
        options = options ? options : [];
        isPaid2SelectValues = options;
        break;
      case "sent_contract":
        if (
          this.state.sentContract === "" ||
          this.state.sentContract === null
        ) {
          checked = true;
        } else if (this.state.sentContract === true) {
          checked = false;
        }
        _sentContract = checked;
        options = _sentContract === "" ? null : _sentContract;
        this.setState({ sentContract: options });
        break;

      case "payment_date":
        options = options ? options : null;
        paymentDate = options;
        break;

      default:
        break;
    }
    props.onFilterChanged(props.columnDef.tableData.id, options);
  };

  onItemChange = (options, props, f) => {
    if (f === "user") {
      userSingleValue = options;
    } else if (f === "campaign") {
      campaignSingleValue = options;
    }
    props.onChange(options ? options.value : null);
  };

  exportPopupClose = () => {
    this.setState({exportPopup: false})
  }

  render() {
    const { isPaid, sentContract, isSuperAdmin } = this.state;
    return (
      <div id="">
        <Container id="faqs" maxWidth="xl">
          <ExportInvestments open={this.state.exportPopup} handleClose={() => this.exportPopupClose()} />
          
          <MaterialTable
            tableRef={this.tableRef}
            columns={[
              {
                title: "Id",
                field: "id",
                editable: "never",
                render: (rowData) =>
                  rowData ? (
                    <Link to={`/investment/${rowData.id}`}>{rowData.id}</Link>
                  ) : (
                    ""
                  ),
              },
              {
                title: "Investor",
                field: "user.id",
                render: (rowData) => (
                  <div>
                    {isSuperAdmin ? (
                      <Link to={`/user/${rowData.user.id}`}>
                        {rowData.user.ic_name}
                      </Link>
                    ) : (
                      <Link onClick={this.isNotSuperAdmin}>
                        {rowData.user.ic_name}
                      </Link>
                    )}
                  </div>
                ),
                filterComponent: (props) => (
                  <CustomAsyncSelect
                    cacheOptions
                    loadOptions={loadUserOptions}
                    defaultOptions
                    onInputChange={(e) => this.onInputChange(e, "user")}
                    value={userSelectValues}
                    placeholder="Search... ( min. 3 characters )"
                    onChange={(option) =>
                      this.onItemSelectChange(option, props, "user")
                    }
                    isMulti={true}
                  />
                ),
                editComponent: (props) => (
                  <CustomAsyncSelect
                    cacheOptions
                    loadOptions={loadUserOptions}
                    defaultOptions
                    onInputChange={(e) => this.onInputChange(e, "user")}
                    value={userSingleValue}
                    onChange={(option) =>
                      this.onItemChange(option, props, "user")
                    }
                    isMulti={false}
                  />
                ),
              },
              {
                title: "Campaign",
                field: "campaign.id",
                render: (rowData) => (
                  <Link to={`/campaign/${rowData.campaign.id}`}>
                    {rowData.campaign.name}
                  </Link>
                ),
                filterComponent: (props) => (
                  <CustomAsyncSelect
                    cacheOptions
                    loadOptions={loadCampaignOptions}
                    defaultOptions
                    onInputChange={(e) => this.onInputChange(e, "campaign")}
                    value={campaignSelectValues}
                    onChange={(option) =>
                      this.onItemSelectChange(option, props, "campaign")
                    }
                    isMulti={true}
                  />
                ),
                editComponent: (props) => (
                  <CustomAsyncSelect
                    cacheOptions
                    loadOptions={loadCampaignOptions}
                    defaultOptions
                    onInputChange={(e) => this.onInputChange(e, "campaign")}
                    value={campaignSingleValue}
                    onChange={(option) =>
                      this.onItemChange(option, props, "campaign")
                    }
                    isMulti={false}
                  />
                ),
              },
              {
                title: "Payment Type",
                field: "payment_method",
                lookup: {
                  "bank-transfer": "Bank Transfer",
                  xfers: "Xfers",
                  paypal: "Paypal",
                  "kb-wallet": "KB Wallet",
                },
                filterComponent: (props) => (
                  <Select
                    options={[
                      {
                        value: "bank-transfer",
                        label: "Bank Transfer",
                      },
                      {
                        value: "xfers",
                        label: "Xfers",
                      },
                      {
                        value: "paypal",
                        label: "Paypal",
                      },
                      {
                        value: "kb-wallet",
                        label: "KB Wallet",
                      },
                    ]}
                    onChange={(option) =>
                      this.onItemSelectChange(option, props, "payment_method")
                    }
                    value={paymentMethodSelectValues}
                    isMulti
                    styles={{
                      control: (styles) => ({ ...styles, minWidth: "250px" }),
                    }}
                  />
                ),
              },
              {
                title: "Funding Amount",
                field: "amount",
                type: "currency",
                filterComponent: (props) => (
                  <Input
                    value={_amount}
                    onChange={(value) =>
                      this.onItemSelectChange(value, props, "amount")
                    }
                    startAdornment={
                      <InputAdornment position="start">
                        <FilterListIcon />
                      </InputAdornment>
                    }
                  />
                ),
              },
              {
                title: "Payment Status",
                field: "is_paid",
                type: "string",
                lookup: {
                  '0': "Unpaid",
                  '1': "Paid",
                  '2': "Pending Approval",
                },
                render: (rowData) => (
                  <>
                  {rowData.is_paid === 0 ? "Unpaid" : rowData.is_paid === 1 ? "Paid" : "Pending Approval"}
                  </>
                ),
                filterComponent: (props) => (
                  <Select
                  isMulti
                  
                    options={[
                      {
                        value: '0',
                        label: "Unpaid",
                      },
                      {
                        value: '1',
                        label: "Paid",
                      },
                      {
                        value: '2',
                        label: "Pending Approval",
                      },
                    ]}
                    onChange={(option) =>
                      this.onItemSelectChange(option, props, "is_paid_2")
                    }
                    value={isPaid2SelectValues}
                    styles={{
                      control: (styles) => ({ ...styles, minWidth: "185px" }),
                    }}
                  />
                ),
              },
              // {
              //   title: "Payment Status",
              //   field: "is_paid",
              //   type: "number",
              //   emptyValue: false,
              //   editable: "never",
              //   render: (rowData) => (
              //     <>
              //     {rowData.is_paid === 1 ? (
              //       <DoneIcon />
              //     ) : (
              //       <RemoveIcon />
              //     )}
              //     </>
              //   ),
              //   filterComponent: (props) => (
              //     <Checkbox
              //       checked={isPaid}
              //       value={_isPaid}
              //       onChange={(option) =>
              //         this.onItemSelectChange(option, props, "is_paid")
              //       }
              //       name="is_paid"
              //       color="primary"
              //       indeterminate={isPaid === false}
              //     />
              //   ),
              // },
              {
                title: "Contract Status",
                field: "sent_contract",
                type: "boolean",
                emptyValue: false,
                editable: "never",
                filterComponent: (props) => (
                  <Checkbox
                    checked={sentContract}
                    value={_sentContract}
                    onChange={(option) =>
                      this.onItemSelectChange(option, props, "sent_contract")
                    }
                    name="sent_contract"
                    color="primary"
                    indeterminate={sentContract === false}
                  />
                ),
              },
              {
                title: "Date of Payment",
                field: "created_at",
                editable: "never",
                type: "datetime",
                filterComponent: (props) => (
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="MM/dd/yyyy"
                    format="MM/dd/yyyy"
                    value={paymentDate}
                    onChange={(option) =>
                      this.onItemSelectChange(option, props, "payment_date")
                    }
                  />
                ),
              },
            ]}
            title="All Investments"
            options={{
              pageSize: 20,
              pageSizeOptions: [10, 20, 50, 100, 200],
              actionsColumnIndex: -1,
              filtering: true
            }}
            data={(query) =>
              new Promise(async (resolve) => {
                let field = query.orderBy ? query.orderBy.field : "";
                let filters = [];

                if (userSelectValues.length) {
                  filters.push({
                    column: { field: "user.id" },
                    value: userSelectValues,
                  });
                }
                if (campaignSelectValues.length) {
                  campaignSelectValues.map((obj) => {
                    // delete obj.label;
                    return obj;
                  });
                  filters.push({
                    column: { field: "campaign.id" },
                    value: campaignSelectValues,
                  });
                }
                if (paymentMethodSelectValues.length) {
                  filters.push({
                    column: { field: "payment_method" },
                    value: paymentMethodSelectValues,
                  });
                }
                if (isPaid2SelectValues.length) {
                  filters.push({
                    column: { field: "is_paid" },
                    value: isPaid2SelectValues,
                  });
                }
                if (_amount) {
                  filters.push({ column: { field: "amount" }, value: _amount });
                }
                if (_isPaid !== null && _isPaid !== "") {
                  filters.push({
                    column: { field: "is_paid" },
                    value: _isPaid,
                  });
                }
                if (_sentContract) {
                  filters.push({
                    column: { field: "sent_contract" },
                    value: _sentContract,
                  });
                }
                if (paymentDate) {
                  filters.push({
                    column: { field: "created_at" },
                    value: paymentDate,
                  });
                }

                filters = filters.length ? JSON.stringify(filters) : "";

                const result = await api.get(
                  `investment?limit=${query.pageSize}&offset=${
                    query.page * query.pageSize
                  }&search=${query.search}&orderDirection=${
                    query.orderDirection
                  }&field=${field}&filters=${filters}`
                );
                const { data } = result;
                
                resolve({
                  data: data.data,
                  page: query.page,
                  totalCount: data.total,
                });
              })
            }
            editable={{
              onRowAdd: (newData) =>
                new Promise(async (resolve) => {
                  userSingleValue = null;
                  campaignSingleValue = null;
                  await api.post(`investment`, newData);
                  resolve();
                }),
              onRowDelete: (oldData) =>
                new Promise(async (resolve) => {
                  await api.delete(`investment/${oldData.id}`);
                  resolve();
                }),
            }}
            actions={[
              {
                icon: "more_horiz",
                tooltip: "Investment Detail",
                onClick: (e, rowData) => {
                  window.open(`/investment/${rowData.id}`, "_blank");
                },
              },
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                onClick: () => {
                  this.tableRef.current &&
                    this.tableRef.current.onQueryChange();
                },
              },
              {
                icon: "save_alt",
                tooltip: "Export",
                isFreeAction: true,
                onClick: () => {
                  this.setState({ exportPopup: true });
                },
              },
            ]}
          />
        </Container>
      </div>
    );
  }
}

export default Page;
let userOptions = [];
let campaignOptions = [];
let userSelectValues = [];
let campaignSelectValues = [];
let userSingleValue = null;
let campaignSingleValue = null;
let paymentMethodSelectValues = [];
let isPaid2SelectValues = [];
let paymentDate = null;
let _isPaid = "";
let _amount = "";
let _sentContract = "";
const loadUserOptions = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(
        userOptions.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }, 1000);
  });
const loadCampaignOptions = (inputValue) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(
        campaignOptions.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }, 1000);
  });
