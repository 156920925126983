import React, {Fragment, Component} from 'react';
import api from 'services/api';
import hp from 'services/hp';
import {
  Card, CardContent,
  FormControlLabel,
  Grid,
  List, ListItem, ListItemIcon, ListItemText,
  Icon,
  Paper,
  Switch,
  Tabs, Tab,
  Typography,
} from '@material-ui/core'
import TemplateEditor from './template-editor';
import Canvas from './canvas';

class SignerTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      preview: null,
      canvasContainers: [],
      previewContainer: null,
      previewLoading: true,
      canvases: {
        0: [], // investor
        1: [], // kb director
        2: [], // company director
        3: [], // company komisaris
      },
      ctxes: {
        0: [], // investor
        1: [], // kb director
        2: [], // company director
        3: [], // company komisaris
      },
      selectedSigner: 0,
      signers: [
        {
          page: null,
          startX: 0,
          startY: 0,
          width: 0,
          height: 0,
          canvasWidth: 0,
          canvasHeight: 0,
        }, // 0 => investor
        {
          page: null,
          startX: 0,
          startY: 0,
          width: 0,
          height: 0,
          canvasWidth: 0,
          canvasHeight: 0,
        }, // 1 => kb director
        {
          page: null,
          startX: 0,
          startY: 0,
          width: 0,
          height: 0,
          canvasWidth: 0,
          canvasHeight: 0,
        }, // 2 => company director
        {
          page: null,
          startX: 0,
          startY: 0,
          width: 0,
          height: 0,
          canvasWidth: 0,
          canvasHeight: 0,
        }, // 2 => company komisaris
      ],
      // signers: JSON.parse(props.parent.state.template.signer_json),
    }
    this.parent = props.parent;
  }

  componentDidMount() {
    if (this.parent.state.template.signer_json !== null) {
      const oldSigners = JSON.parse(this.parent.state.template.signer_json);
      const {signers} = this.state;
      signers.map((signer, selectedSigner) => {
        if (oldSigners[selectedSigner]) {
          signers[selectedSigner] = oldSigners[selectedSigner];
        }
      });
      this.setState({signers});
    }
    this.previewTemplate();
  }

  previewTemplate = async (e, ) => {
    const investment = this.parent.getSelectedOption('investment');
    const postData = {
      investment_id: investment ? investment.id : null,
      single: true,
      content: this.parent.getTemplateContent('signer'),
    }
    await api.post(`contract-template-preview/prepare`, postData).then(async res => {
      this.setState({preview : res.data, previewLoading: false});
    });
  }

  resetCanvas = async () => {
    const { signers, canvases, canvasContainers, ctxes } = this.state;
    await signers.map((item, signer) => {
      if (canvases[signer].length && canvasContainers.length) {
        canvases[signer].map((canvas, page) => {
          if (!canvasContainers[page]) {
            // console.log(`canvasContainers[${page}] not found, trying to resetCanvas again..`);
            setTimeout(() => {
              this.resetCanvas();
            }, 1000);
            return;
          }
          canvases[signer][page].width = canvasContainers[page].offsetWidth;
          canvases[signer][page].height = canvasContainers[page].offsetHeight;
          ctxes[signer][page] = canvases[signer][page].getContext("2d");
          ctxes[signer][page].strokeStyle = signer === 0 ? "orange" : signer === 1 ? "blue" : signer === 2 ? "green" : "grey";
          ctxes[signer][page].lineWidth = 2;
          this.resetCanvasEvents(canvas, page);
          let {width, height} = canvases[signer][page];
          // console.log(`set signer ${signer} canvases[${page}]`, {width, height});
        });
      }
    });
    await this.setState({ctxes, canvases});
    await this.recalculateSignersData();
  }

  resetCanvasEvents = (canvas, page) => {
    canvas.onmousedown = (e, mouseDown = true, isChanged = true) => {
      if (this.state.editSigner) {
        e.preventDefault();
        const { preview } = this.parent.state;
        const { selectedSigner, signers } = this.state;
        
        signers[selectedSigner].startX = e.clientX - this.state.canvasContainers[page].offsetLeft;
        signers[selectedSigner].startY = e.clientY - this.state.canvasContainers[page].offsetTop + this.state.previewContainer.scrollTop + window.scrollY;
        this.parent.setState({ preview, isChanged });
        this.setState({ mouseDown, signers });
      }
    }
    canvas.onmouseup = (e, mouseDown = false) => {
      if (this.state.editSigner) {
        this.setState({ mouseDown });
        // console.log(this.state.signers[this.state.selectedSigner]);
      }
    }
    canvas.onmousemove = (e) => {
      if (this.state.editSigner) {
        const clientX = e.clientX - this.state.canvasContainers[page].offsetLeft;
        const clientY = e.clientY - this.state.canvasContainers[page].offsetTop + this.state.previewContainer.scrollTop + window.scrollY;
        if (this.state.mouseDown) {
          const { template } = this.parent.state;
          const { selectedSigner, signers } = this.state;
          this.clearSign(this.state.selectedSigner,signers[selectedSigner].page);
          signers[selectedSigner].page = page;
          signers[selectedSigner].width = clientX - signers[selectedSigner].startX;
          signers[selectedSigner].height = clientY - signers[selectedSigner].startY;
          signers[selectedSigner].canvasWidth = canvas.width;
          signers[selectedSigner].canvasHeight = canvas.height;
          
          this.state.ctxes[selectedSigner][page].clearRect(0, 0, canvas.width, canvas.height);
          template.signer_json = JSON.stringify(signers);
          this.setState({signers});
          this.parent.setState({template});
          this.setSign(selectedSigner);
        }
      }
    }
  }

  setSign = (selectedSigner) => {
    const signer = this.state.signers[selectedSigner];
    if (signer) {
      if (this.state.ctxes[selectedSigner][signer.page]) {
        this.state.ctxes[selectedSigner][signer.page].strokeRect(signer.startX, signer.startY, signer.width, signer.height);
      }
    }
  }

  resetSign = () => {
    this.state.signers.map((signer, selectedSigner) => {
      this.setSign(selectedSigner);
    })
  }

  clearSign = (selectedSigner, page) => {
    const signer = this.state.signers[selectedSigner];
    const canvas = this.state.canvases[selectedSigner][page];
    if (!signer) {
      alert('Error: No signer selected.');
      return;
    }
    if (canvas) {
      this.state.ctxes[selectedSigner][page].clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  recalculateSignersData = async () => {
    const { signers } = this.state;
    await signers.map((signer, selectedSigner) => {
      const canvas = this.state.canvases[selectedSigner][signer.page];
      if (canvas) {
        signers[selectedSigner].startX = canvas.width * (signers[selectedSigner].startX / signers[selectedSigner].canvasWidth);
        signers[selectedSigner].startY = canvas.height * (signers[selectedSigner].startY / signers[selectedSigner].canvasHeight);
        signers[selectedSigner].width = canvas.width * (signers[selectedSigner].width / signers[selectedSigner].canvasWidth);
        signers[selectedSigner].height = canvas.height * (signers[selectedSigner].height / signers[selectedSigner].canvasHeight);
        signers[selectedSigner].canvasWidth = canvas.width;
        signers[selectedSigner].canvasHeight = canvas.height;
        this.clearSign(selectedSigner, signer.page);
      }
    });
    await this.setState({signers});
    await this.resetSign();
  }

  goToPage = (page, additional_y = 0) => {
    const {canvasContainers, previewContainer} = this.state;
    previewContainer.scrollTo({
      left: 0, 
      top: canvasContainers[page].offsetTop - previewContainer.offsetTop + Math.max(additional_y - 100, 0),
      behavior: 'smooth',
    });
    // canvasContainers[page].scrollIntoView({behavior: 'smooth', block: 'center'});
  }

  render() {
    return (
      <Fragment>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} md={4} >
            <Paper style={{marginTop: '10px'}}>
              <Tabs
                value={this.state.activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, activeTab) => {
                  this.setState({activeTab});
                  if (activeTab == 1) {
                    this.previewTemplate(e);
                  }
                }}
                variant="fullWidth"
              >
                <Tab label="Editor" />
                <Tab label="Location" />
              </Tabs>
            </Paper>
          </Grid>
        </Grid>
        {
          this.state.activeTab == 0 &&
          <TemplateEditor 
            content={this.parent.getTemplateContent} 
            type="signer" 
            id="signer-content"
            className="space"
            onChange={this.parent.setTemplateContent}
          />
        }
        {
          this.state.activeTab == 1 &&
          <Fragment>
            <Grid container spacing={1} justify="center">
              <Grid item xs={12} md={3}>
                <Card className="w-100 h-fit-content space">
                  <Grid container spacing={1} component={CardContent}>
                    <Grid item xs={12}>
                      <Typography variant="h5" style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                        Signers
                        <FormControlLabel control={
                          <Switch
                            disabled={this.state.previewLoading}
                            checked={this.state.editSigner}
                            onChange={e => {
                              this.setState({editSigner: e.target.checked});
                              this.resetCanvas();
                            }}
                            color="primary"
                          />
                        } label="Edit-mode" />
                      </Typography>
                      <List>
                      {[['Investor', 'orange'], ['KB Director', 'blue'], ['Company Director', 'green'], ['Company Komisaris', 'grey']].map((item, signer) => (
                        <ListItem
                          key={signer}
                          button
                          selected={this.state.editSigner && this.state.selectedSigner === signer}
                          disabled={!this.state.editSigner}
                          onClick={(e) => {
                            const { signers, canvasContainers } = this.state;
                            this.setState({selectedSigner: signer});
                            if (signers[signer].page !== null && canvasContainers[signers[signer].page]) {
                              this.goToPage(signers[signer].page, signers[signer].startY);
                            }
                          }}
                        >
                          <ListItemIcon>
                            <Icon style={{ color: item[1] }}>assignment_ind</Icon>
                          </ListItemIcon>
                          <ListItemText>{item[0]}</ListItemText>
                          {
                            this.state.editSigner && this.state.signers[signer].page !== null &&
                            <ListItemIcon>
                              <Icon style={{ color: 'deeppink' }} onClick={(e) => {
                                const {signers} = this.state;
                                const {template} = this.parent.state;
                                signers[signer].page = null;
                                template.signer_json = JSON.stringify(signers);

                                this.parent.setState({isChanged: true, template});
                                this.setState({signers});
                                this.resetCanvas();
                              }}>cancel</Icon>
                            </ListItemIcon>
                          }
                        </ListItem>
                      ))}
                      </List>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12} md={7} className="preview-container" ref={ref => this.state.previewContainer = ref}>
                { !this.state.preview && hp.Loading() }
                {
                  this.state.preview &&
                  <Fragment>
                    {[...Array(this.state.preview.page_count)].map((item, page) => <Canvas key={page} src={`${this.state.preview.url}/${page}.png`} page={page} parent={this}/>)}
                  </Fragment>
                }
              </Grid>
              <Grid item xs={12} md={2} className="preview-container">
                {
                  this.state.preview && 
                  <Fragment>
                    {[...Array(this.state.preview.page_count)].map((item, page) =>  
                      <div key={page} className="w-100 pos-rel">
                        <span className="page-number" onClick={e => this.goToPage(page)}>
                          <span className="page-number-item">{page+1}</span>
                        </span>
                        <img className="w-100" src={`${this.state.preview.url}/${page}.png`} alt='img'></img>
                      </div>
                    )}
                  </Fragment>
                }
              </Grid>
            </Grid>
          </Fragment>
        }
      </Fragment>
    );
  }
}

export default SignerTab;