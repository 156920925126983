import React, { Component, Fragment, useState } from "react";

import {
  Container,
  NativeSelect,
  CircularProgress,
  Paper,
  Snackbar,
  Tabs,
  Tab,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  TextField,
  Grid,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
} from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { format } from "date-fns";
import { DatePicker } from "@material-ui/pickers";

import api from "services/api";
import hp from "services/hp";
import MaterialTable, { MTableToolbar } from "material-table";
import BasisId from './Components/BasisId';
import Veriff from './Components/Veriff';
import { FileCopyOutlined } from "@material-ui/icons";
import { withRouter } from "react-router-dom";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      user: null,
      basisid: null,
      veriff: null,
      allBanks: null,
      bankAccounts: null,
      bank_name: "",
      id_user: props.match.params.id_user,
      openSuccessSnack: false,
      save_loading: false,
      nric_file: null,
      nric_file_back: null,
      address_proof: null,
      countries: [],
      tab: 0,
      saldo: 0,
      resetPasswordLink: true,
      verificationLink: false,
      forgetPasswordLink: false,
      openDeleteWithdrawalDialog: false,
      user_password: "",
      withdraw_to_delete: "",
      openDeleteTransactionDialog: false,
      transaction_to_delete: "",
      openDeleteAccountDialog: false
    };
    this.walletTable = React.createRef();
    this.withdrawTable = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
    this.saldoWallet();
  }
  onStart = async () => {
    this.setState({user: null})
    const { id } = this.state;
    // * res
    const res = await api.get(`user/${id}`);
    const resBankAccounts = await api.get(`bank?user_id=${id}`);
    const resAllBanks = await api.get(`banks-all`);
    // * data
    const user = res.data;
    // console.log(user);
    
    const basisidRes = await api.get(`basisid/show/${id}`);
    const basisid = basisidRes.data.data;

    const veriffRes = await api.get(`veriff/show/${id}`);
    const veriff = veriffRes.data.data;

    const bankAccounts = resBankAccounts.data; // detailBank
    const allBanks = resAllBanks.data.data;
    // console.log(allBanks);
    // console.log(bankAccounts);
    const countries = await hp.get_countries();
    this.setState({ user, basisid, veriff, countries, allBanks, bankAccounts });
  };

  // todo bismillah try to get data saldo wallet transaction
  saldoWallet = async () => {
    const { id } = this.state;
    const res = await api.get(`kb-wallet/getSaldoTransaction/${id}`);
    const saldo = res.data;
    this.setState({ saldo });
  };

  // * onUpdate old fix
  onUpdateFix = async (e) => {
    e.preventDefault();
    const { user, id } =
      this.state;
    const res = await api.post(`user/${id}`, user);
    const { data } = res; // const
    console.log(data);
    if (data.error) {
      alert(data.message);
    }
    window.scrollTo(0, 0);
    this.setState({
      openSuccessSnack: true,
      nric_file: null,
      nric_file_back: null,
      address_proof: null,
    });
    hp.saveUsers(data);
    this.onStart();
  };

  // * onUpdate new
  onUpdate = async (e) => {
    e.preventDefault();
    const { user, id, nric_file, nric_file_back, address_proof } =
      this.state;
    let formData = new FormData();
    if (user.firstname) {
      formData.append("firstname", user.firstname);
    }
    if (user.lastname) {
      formData.append("lastname", user.lastname);
    }
    if (user.phone_no) {
      formData.append("phone_no", user.phone_no);
    }
    if (user.address) {
      formData.append("address", user.address);
    }
    if (user.nric) {
      formData.append("nric", user.nric);
    }
    if (user.ic_type) {
      formData.append("ic_type", user.ic_type);
    }
    if (user.ic_name) {
      formData.append("ic_name", user.ic_name);
    }
    if (user.ic_country) {
      formData.append("ic_country", user.ic_country);
    }
    if (user.country) {
      formData.append("country", user.country);
    }
    if (user.nationality) {
      formData.append("nationality", user.nationality);
    }
    if (user.dob) {
      formData.append("dob", user.dob);
    }
    if (user.status) {
      formData.append("status", user.status);
    }
    if (user.how_you_know) {
      formData.append("how_you_know", user.how_you_know);
    }
    if (nric_file) {
      formData.append("nric_file", nric_file);
    }
    if (nric_file_back) {
      formData.append("nric_file_back", nric_file_back);
    }
    if (address_proof) {
      formData.append("address_proof", address_proof);
    }
    this.setState({ save_loading: true });
    try {
      const res = await api.post(`user/${id}`, formData);
      this.setState({ save_loading: false });
      let { data } = res;
      if (data.error) {
        alert(data.message);
        return;
      }
      window.scrollTo(0, 0);
      this.setState({
        openSuccessSnack: true,
        nric_file: null,
        nric_file_back: null,
        address_proof: null,
        user: data,
      });
      hp.saveUsers(data);
    } catch (e) {
      console.log(e);
      alert("Something went wrong");
    }
    this.onStart();
  };

  onChange = (e, f) => {
    let { user } = this.state;
    user[f] = e.target.value;
    this.setState({ user });
  };
  onChangeLinkForInvestor = (e, f) => {
    let { user, verificationLink, resetPasswordLink, forgetPasswordLink } =
      this.state;
    user[f] = e.target.value;
    if (user[f] === "verificationLink") {
      verificationLink = true;
      resetPasswordLink = false;
      forgetPasswordLink = false;
      this.setState({ verificationLink, resetPasswordLink });
    } else if (user[f] === "resetPasswordLink") {
      verificationLink = false;
      resetPasswordLink = true;
      forgetPasswordLink = false;
      this.setState({ verificationLink, resetPasswordLink });
    } else if (user[f] === "forgetPasswordLink") {
      verificationLink = false;
      resetPasswordLink = false;
      forgetPasswordLink = true;
      this.setState({
        verificationLink,
        resetPasswordLink,
        forgetPasswordLink,
      });
    }
  };
  onAttach = (e, f) => {
    let { state } = this;
    state[f] = e.target.files[0];
    this.setState({ state });
  };
  onChangeDOB = (date) => {
    let { user } = this.state;
    user.dob = format(new Date(date), "yyyy-MM-dd");
    this.setState({ user });
  };

  onChangeBank = async (e) => {
    console.log(e);
  };

  onChangeTab = (e, newTab) => {
    this.setState({ tab: newTab });
    if(newTab === 0) this.onStart();
  };

  onShowConfirmDialog = (data, value) => {
    this.setState({withdraw_to_delete: data.id})
    this.setState({openDeleteWithdrawalDialog: value})
  }

  onShowConfirmTransactionDialog = (data, value) => {
    this.setState({transaction_to_delete: data.id})
    this.setState({openDeleteTransactionDialog: value})
  }
  
  handleDelete = async () => {
    if (this.state.withdraw_to_delete) {
      const res = await api.post(`user/withdraw/${this.state.withdraw_to_delete}?_method=DELETE`, {ps: this.state.user_password});
  
      let { data } = res;
      if (data.error) {
        alert(data.message);
        return;
      }
  
      alert("Withdraw request deleted.")
  
      this.withdrawTable.current && this.withdrawTable.current.onQueryChange()
  
      setTimeout(() => {
        this.setState({
          openDeleteWithdrawalDialog: false,
          user_password: null,
          withdraw_to_delete: null
        });
      }, 500);
    }

    return;
  }

  handleDeleteTransaction = async () => {
    this.saldoWallet();
    
    const res = await api.post(`kb-wallet/transaction/${this.state.transaction_to_delete}?_method=DELETE`, {ps: this.state.user_password});
    // await api.delete(`kb-wallet/transaction/${this.state.transaction_to_delete}`);

    let { data } = res;
    if (data.error) {
      alert(data.message);
      return;
    }

    alert("Wallet transaction deleted.")

    this.walletTable.current && this.walletTable.current.onQueryChange()

    setTimeout(() => {
      this.setState({
        openDeleteTransactionDialog: false,
        user_password: null,
        transaction_to_delete: null
      });
    }, 500);
  }

  handleDeleteAccount = async () => {    
    const res = await api.post(`user/delete/${this.state.id}?_method=DELETE`, {ps: this.state.user_password});

    let { data } = res;
    if (data.error) {
      alert(data.message);
      return;
    }

    alert(data.message)

    this.setState({
      openDeleteAccountDialog: false,
      user_password: null,
    });

    setTimeout(() => {
      this.props.history.push("/user");
    }, 500);
  }

  render() {
    const { user, 
      veriff, 
      tab, 
      openDeleteWithdrawalDialog, 
      user_password,
      openDeleteTransactionDialog,
      openDeleteAccountDialog
    } = this.state;
    return (
      <>
        <Dialog
          open={openDeleteWithdrawalDialog}
          onClose={() => this.setState({openDeleteWithdrawalDialog: false})}
          fullWidth={false}
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            Delete withdraw request
          </DialogTitle>
          <DialogContent>
            <h4 style={{marginTop: '0'}}>Enter your password</h4> 
            <TextField placeholder="Enter your password" type={`password`} className="form-control" onChange={(e) => this.setState({'user_password': e.target.value})} value={user_password}  fullWidth style={{marginBottom: '15px'}} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleDelete()}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
            <Button
              onClick={() => this.setState({openDeleteWithdrawalDialog: false})}
              variant="contained"
              color="default"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteTransactionDialog}
          onClose={() => this.setState({openDeleteTransactionDialog: false})}
          fullWidth={false}
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            Delete wallet transaction
          </DialogTitle>
          <DialogContent>
            <h4 style={{marginTop: '0'}}>Enter your password</h4> 
            <TextField placeholder="Enter your password" type={`password`} className="form-control" onChange={(e) => this.setState({'user_password': e.target.value})} value={user_password}  fullWidth style={{marginBottom: '15px'}} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleDeleteTransaction()}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
            <Button
              onClick={() => this.setState({openDeleteTransactionDialog: false})}
              variant="contained"
              color="default"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDeleteAccountDialog}
          onClose={() => this.setState({openDeleteAccountDialog: false})}
          fullWidth={false}
          maxWidth="sm"
        >
          <DialogTitle id="alert-dialog-title">
            Delete user account
          </DialogTitle>
          <DialogContent>
            <h4 style={{marginTop: '0'}}>Enter your password</h4> 
            <TextField placeholder="Enter your password" type={`password`} className="form-control" onChange={(e) => this.setState({'user_password': e.target.value})} value={user_password}  fullWidth style={{marginBottom: '15px'}} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleDeleteAccount()}
              variant="contained"
              color="primary"
            >
              Delete Account
            </Button>
            <Button
              onClick={() => this.setState({openDeleteAccountDialog: false})}
              variant="contained"
              color="default"
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Container id="user" maxWidth="xl">
          <Paper square className="spaceBottom">
            <Tabs value={tab} onChange={this.onChangeTab}>
              <Tab label="Profile" />
              <Tab label="Bank Account" />
              <Tab label="Wallet Transaction" />
              <Tab label="Wallet Withdrawal" />
              <Tab label="Veriff" />
            </Tabs>
          </Paper>
          {user ? (
            <Fragment>
              {tab === 0 && this.renderProfile()}
              {tab === 1 && this.renderBankAccount()}
              {tab === 2 && this.renderTransaction(this.onShowConfirmTransactionDialog)}
              {tab === 3 && this.renderWithdrawal(this.onShowConfirmDialog)}
              {tab === 4 && <Veriff user={user} veriff={veriff} />}
            </Fragment>
          ) : (
            <div className="loading">
              <CircularProgress />
            </div>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.openSuccessSnack}
            autoHideDuration={6000}
            onClose={() => this.setState({ openSuccessSnack: false })}
          >
            <div className="success-alert">
              <Typography>Successfully updated</Typography>
            </div>
          </Snackbar>
        </Container>
      </>
    );
  }

  renderProfile() {
    const {
      user,
      countries,
      nric_file,
      nric_file_back,
      address_proof,
      save_loading,
      verificationLink,
      resetPasswordLink,
      forgetPasswordLink,
    } = this.state;
    return (
      <form onSubmit={this.onUpdate}>
        <Paper className="padding">
          <Grid container spacing={5}>
            <Grid item xs={6}>
              {[
                ["First Name", "firstname"],
                ["Last Name", "lastname"],
                ["Full Name in IC", "ic_name"],
                ["NRIC/Passport No", "nric"],
                ["Email", "email"],
                ["Phone No", "phone_no"],
                ["Residential Address", "address"],
                ["How You Know Us", "how_you_know"],
              ].map((field, i) => (
                <TextField
                  key={i}
                  margin="normal"
                  label={field[0]}
                  type="text"
                  fullWidth
                  value={user[field[1]] ? user[field[1]] : ""}
                  onChange={(e) => this.onChange(e, field[1])}
                />
              ))}
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>User Status</InputLabel>
                <Select
                  value={user.status}
                  onChange={(e) => this.onChange(e, "status")}
                >
                  <MenuItem value="new">New</MenuItem>
                  <MenuItem value="reviewing">Waiting to be reviewed</MenuItem>
                  <MenuItem value="approved">Approved</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                  <MenuItem value="blacklisted">Blacklisted</MenuItem>
                </Select>
              </FormControl>
              <DatePicker
                disableFuture
                variant="inline"
                format="dd MMMM yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Date of Birth"
                value={user.dob ? new Date(user.dob) : null}
                onChange={(e) => this.onChangeDOB(e)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                fullWidth
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Country</InputLabel>
                <NativeSelect
                  value={user.country ? user.country : ""}
                  onChange={(e) => this.onChange(e, "country")}
                >
                  <option value=""></option>
                  {countries &&
                    countries.map((country, i) => (
                      <option key={i} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Nationality</InputLabel>
                <NativeSelect
                  value={user.nationality ? user.nationality : ""}
                  onChange={(e) => this.onChange(e, "nationality")}
                >
                  <option value=""></option>
                  {countries &&
                    countries.map((country, i) => (
                      <option key={i} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel>IC Type</InputLabel>
                <Select
                  value={user.ic_type ? user.ic_type : ""}
                  onChange={(e) => this.onChange(e, "ic_type")}
                >
                  <MenuItem value="Identity Card">Identity Card</MenuItem>
                  <MenuItem value="FIN">FIN</MenuItem>
                  <MenuItem value="Passport">Passport</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <InputLabel>IC Country</InputLabel>
                <NativeSelect
                  value={user.ic_country ? user.ic_country : ""}
                  onChange={(e) => this.onChange(e, "ic_country")}
                >
                  <option value=""></option>
                  {countries &&
                    countries.map((country, i) => (
                      <option key={i} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>

              <FormControl fullWidth margin="normal">
                <TextField
                  margin="normal"
                  label="Status Verified (Uneditable)"
                  type="text"
                  fullWidth
                  value={`${user.is_verified ? "Verified" : "Not Verified"}`}
                  multiline
                />
              </FormControl>
              {/* //TODO: Start Try to make dropdown password reset link, verification, and activate account */}
              <FormControl fullWidth margin="normal">
                <InputLabel>
                  Reset Password Link ,Verification Link, and Forget Password
                  Link
                </InputLabel>
                <NativeSelect
                  onChange={(e) =>
                    this.onChangeLinkForInvestor(e, "linkForInvestor")
                  }
                >
                  <option value="resetPasswordLink" selected>
                    Reset Password
                  </option>
                  <option value="verificationLink">Verification</option>
                  <option value="forgetPasswordLink">Forget Password</option>
                </NativeSelect>
              </FormControl>
              {resetPasswordLink ? (
                <FormControl fullWidth margin="normal">
                  <TextField
                    margin="normal"
                    label="Reset Password Link (Web Migration, Uneditable)"
                    type="text"
                    fullWidth
                    value={`${hp.publicUrl}/activate/${user.validation_code}`}
                    multiline
                  />
                </FormControl>
              ) : verificationLink ? (
                <FormControl fullWidth margin="normal">
                  <TextField
                    margin="normal"
                    label="Verification Link (New Investors, Uneditable)"
                    type="text"
                    fullWidth
                    value={`${hp.publicUrl}/verify/${user.validation_code}`}
                    multiline
                  />
                </FormControl>
              ) : forgetPasswordLink ? (
                <FormControl fullWidth margin="normal">
                  <TextField
                    margin="normal"
                    label="Forget Password Link (Uneditable)"
                    type="text"
                    fullWidth
                    value={`${hp.publicUrl}/forget/${user.validation_code}`}
                    multiline
                  />
                </FormControl>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Paper>

        <Paper className="spaceBigX padding" style={{ paddingBottom: 30 }}>
          <Typography variant="h5" className="bold spaceBottomX">
            Upload Proof of Identification
          </Typography>
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <Typography className="bold space">
                Identification Card / Passport (front)
              </Typography>
              {user.nric_file ? (
                <img src={user.nric_file} width="100%" alt="img"/>
              ) : (
                <Typography className="dim">
                  No NRIC/Passport Uploaded
                </Typography>
              )}
              <input
                accept="image/*"
                id="profile-nric-file"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => this.onAttach(e, "nric_file")}
              />
              <label htmlFor="profile-nric-file">
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  className="spaceBig"
                >
                  Browse
                </Button>
                {nric_file && (
                  <Typography>Attached: {nric_file.name}</Typography>
                )}
              </label>
            </Grid>
            <Grid item xs={4}>
              <Typography className="bold space">
                Identification Card (back)
              </Typography>
              {user.nric_file_back ? (
                <img src={user.nric_file_back} width="100%" alt="img"/>
              ) : (
                <Typography className="dim">No NRIC Back Uploaded</Typography>
              )}
              <input
                accept="image/*"
                id="profile-nric-file-back"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => this.onAttach(e, "nric_file_back")}
              />
              <label htmlFor="profile-nric-file-back">
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  className="spaceBig"
                >
                  Browse
                </Button>
                {nric_file_back && (
                  <Typography>Attached: {nric_file_back.name}</Typography>
                )}
              </label>
            </Grid>
            <Grid item xs={4}>
              <Typography className="bold space">Proof of Address </Typography>
              {user.address_proof ? (
                <>
                  {user.address_proof.includes(".pdf") ? (
                    <a href={user.address_proof} target="_blank">
                      <FileCopyOutlined />
                      &nbsp; Open File
                    </a>
                  ) : (
                    <img src={user.address_proof} width="100%" alt="img"/>
                  )}
                </>
              ) : (
                <Typography className="dim">
                  No Address Proof Uploaded
                </Typography>
              )}
              <input
                accept="image/*"
                id="profile-address-proof"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => this.onAttach(e, "address_proof")}
              />
              <label htmlFor="profile-address-proof">
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  className="spaceBig"
                >
                  Browse
                </Button>
                {address_proof && (
                  <Typography>Attached: {address_proof.name}</Typography>
                )}
              </label>
            </Grid>
          </Grid>
        </Paper>

        <Paper className="spaceBottomX padding">
          <Grid container spacing={5}>
            <Grid item xs={8}>
              {save_loading ? (
                <Button
                  margin="normal"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="spaceBig" // space
                >
                  <CircularProgress color="default" size={25} />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  className="spaceBig"
                  color="primary"
                >
                  Update
                </Button>
              )}
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                fullWidth
                className="spaceBig"
                color="secondary"
                onClick={() => this.setState({openDeleteAccountDialog: true})}
              >
                Delete Account
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    );
  }

  renderBankAccount() {
    const { allBanks } = this.state;
    console.log(allBanks);
    return (
      <MaterialTable
        title="Bank Account"
        columns={[
          {
            title: "Bank Name",
            field: "bank_name", //bank_name
            editComponent: (props) => (
              <Select
                value={props.value}
                placeholder="Bank Name"
                displayEmpty
                onChange={(e) => props.onChange(e.target.value)}
              >
                <MenuItem selected> None </MenuItem>
                {allBanks.map((all_bank, i) => (
                  <MenuItem value={all_bank.bank_name}>
                    {all_bank.bank_name}
                  </MenuItem>
                ))}
              </Select>
            ),
          },
          { title: "Account Name", field: "account_name" },
          { title: "Account Number", field: "account_number" },
          {
            title: "Country of Bank",
            field: "bank.country",
            editable: "never",
          },
          { title: "Currency", field: "bank.currency", editable: "never" },
          { title: "SWIFT Code", field: "bank.swift_code", editable: "never" },
          { title: "IBAN Code", field: "bank.iban_code", editable: "never" },
          { title: "Last Update", field: "updated_at", editable: "never" },
          { title: "Created", field: "created_at", editable: "never" },
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10],
          actionsColumnIndex: -1,
        }}
        data={(query) =>
          new Promise(async (resolve) => {
            const result = await api.get(
              `bank?limit=${query.pageSize}&offset=${
                query.page * query.pageSize
              }&search=${query.search}&user_id=${this.state.id}`
            );
            const { data } = result;
            console.log(data);
            resolve({
              data: data.data,
              page: query.page,
              totalCount: data.total,
            });
          })
        }
        editable={{
          onRowAdd: (newData) =>
            new Promise(async (resolve) => {
              newData.user_id = this.state.id;
              await api.post("bank", newData);
              resolve();
            }),
          onRowUpdate: (newData) =>
            new Promise(async (resolve) => {
              delete newData.created_at;
              delete newData.updated_at;
              // console.log(newData);
              await api.post(
                `bank/${newData.id}?user_id=${newData.user_id}?account_name=${newData.account_name}?bank_name=${newData.bank_name}?account_number=${newData.account_number}`,
                newData
              );
              resolve();
            }),
          onRowDelete: (oldData) =>
            new Promise(async (resolve) => {
              await api.delete(`bank/${oldData.id}`);
              resolve();
            }),
        }}
      />
    );
  }

  renderTransaction(onShowTransactionConfirmDialog) {
    const { saldo, user } = this.state;
    
    return (
      <MaterialTable
        tableRef={this.walletTable}
        title={`KB Wallet Transaction - ${user.full_name}`}
        columns={[
          {
            title: "Title",
            field: "title",
            lookup: {
              "top-up": "Top Up",
              withdrawal: "Withdrawal",
              investment: "Investment",
              donation: "Donation",
              payout: "Payout",
              cashback: "Cashback",
              "reduce-balance": "Reduce Balance",
            },
          },
          { title: "Description", field: "description" },
          { title: "Amount", field: "amount" , render: (rowData) => (
            <Chip variant="default" color={rowData.amount < 0 ? "secondary" : "primary"} label={hp.format_money_symbol(rowData.amount)} style={rowData.amount < 0 ? {background: '#e74c3c'} : {}} />
          ),},
          { title: "Created", field: "created_at", editable: "never" },
        ]}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 50],
          actionsColumnIndex: -1,
        }}
        data={(query) =>
          new Promise(async (resolve) => {
            const result = await api.get(
              `kb-wallet/transaction?limit=${query.pageSize}&offset=${
                query.page * query.pageSize
              }&search=${query.search}&user_id=${this.state.id}`
            );
            const { data } = result;
            resolve({
              data: data.data,
              page: query.page,
              totalCount: data.total,
            });
          })
        }
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
              <div className="container" style={{padding: '0 25px'}}>
                <Grid container>
                  <Grid item xs={12}>
                    <h3 style={{margin: '0'}}>
                      Balance : <u>{hp.format_money_symbol(saldo.saldo)}</u>
                    </h3>
                  </Grid>
                </Grid>
              </div>
            </div>
          ),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise(async (resolve) => {
              newData.user_id = this.state.id;
              newData.status = true;
              await api.post("kb-wallet/transaction", newData);
              resolve();
              this.saldoWallet();
            }),
          onRowUpdate: (newData) =>
            new Promise(async (resolve) => {
              delete newData.created_at;
              delete newData.updated_at;
              await api.post(`kb-wallet/transaction/${newData.id}`, newData);
              resolve();
              this.saldoWallet();
            }),
          onRowDelete: (oldData) =>
            new Promise(async (resolve) => {
              onShowTransactionConfirmDialog(oldData, true)

              return resolve(true)
            }),
        }}
        actions={[
          {
            icon: "refresh",
            tooltip: "Refresh Data",
            isFreeAction: true,
            onClick: () =>
              this.walletTable.current &&
              this.walletTable.current.onQueryChange(),
          },
        ]}
      />
    );
  }

  onAttachSub = async (e, props) => {
    let formData = new FormData();
    formData.append("dir", "user-withdraw");
    formData.append("image", e.target.files[0]);
    let image_url_res = await api.post("upload-image", formData);
    props.onChange(image_url_res.data.file_name);
  };

  renderWithdrawal(onShowConfirmDialog) {
    return (
      
        <MaterialTable
          tableRef={this.withdrawTable}
          title="KB Wallet Withdrawal"
          columns={[
            {
              title: "No",
              editable: "never",
              render: (rowData) => (rowData ? rowData.tableData.id + 1 : ""),
            },
            { title: "Amount", field: "amount", type: "currency" },
            { title: "Status", field: "status", type: "boolean" },
            { title: "Date Requested", field: "created_at", editable: "never" },
            {
              title: "Proof",
              field: "proof",
              render: (rowData) =>
                rowData &&
                rowData.proof && (
                  <a href={rowData.proof}>
                    <img src={rowData.proof} height="100px" alt="img"/>
                  </a>
                ),
              editComponent: (props) => (
                <div>
                  {props.rowData.proof && (
                    <div>
                      <img src={props.rowData.proof} height="100px" alt="img"/>
                    </div>
                  )}
                  <input
                    accept="image/*"
                    id={`withdraw-proof-file`}
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => this.onAttachSub(e, props)}
                  />
                  <label htmlFor="withdraw-proof-file">
                    <Button
                      variant="contained"
                      color="secondary"
                      component="span"
                      className="spaceBig"
                    >
                      Browse
                    </Button>
                  </label>
                </div>
              ),
            },
          ]}
          options={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 20, 50],
            actionsColumnIndex: -1,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              const result = await api.get(
                `user/withdraw?limit=${query.pageSize}&offset=${
                  query.page * query.pageSize
                }&search=${query.search}&user_id=${this.state.id}`
              );
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          editable={{
            onRowUpdate: (newData) =>
              new Promise(async (resolve) => {
                delete newData.created_at;
                delete newData.updated_at;
                delete newData.bank;
                await api.post(`user/withdraw/${newData.id}`, newData);
                resolve();
              }),
              onRowDelete: (oldData) =>
                new Promise(async (resolve) => {
                  onShowConfirmDialog(oldData, true)

                  return resolve(true)
                }),
          }}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () =>
                this.withdrawTable.current &&
                this.withdrawTable.current.onQueryChange(),
            },
          ]}
        />
    );
  }
}

export default Page;
