import React from 'react';

import {
    Paper,
    Button,
    Grid,
    TextField,
    InputLabel,
    CircularProgress,
    Snackbar,
    FormControl,
    MenuItem,
    Select
  } from "@material-ui/core";

  import { Alert, AlertTitle } from "@material-ui/lab";

import api from "services/api";

class DataReminder extends React.Component {
    
    constructor(props) {
        super(props);
        this.renderFlag = true;
        
        this.state = {
          campaign_id: props.campaign_id,
          data_reminder: null,
          openAlert: false,
          alert: {
              message: null,
              severity: "info"
          },
          list_bd: [
              {name: "Dede Iskandar", email: "dede@kapitalboost.co.id", is_active: true},
              {name: "Tommy Effendi", email: "tommy.effendi@kapitalboost.co.id", is_active: true},
              {name: "Ahmad Bagus Sudrajad", email: "ahmad.bagus@kapitalboost.co.id", is_active: true},
              {name: "Ata Priyatna", email: "ata.priyatna@kapitalboost.co.id", is_active: false},
              {name: "Herman Budi Cahyono", email: "herman.budi@kapitalboost.co.id", is_active: false},
          ],
          list_analyst: [
              {name: "Achmad Sopfian", email: "sopfian@kapitalboost.co.id", is_active: true},
              {name: "Bari Akbar", email: "bari@kapitalboost.co.id", is_active: true}
          ]
        };
    }

    componentDidMount() {
        this.loadDataReminder();
    }

    loadDataReminder = async (e) => {
        const { campaign_id } = this.state;
        const res = await api.get(`campaign/data-reminder/${campaign_id}`);

        const { data } = res;
        let data_reminder = data;

        if(data) {
            this.setState({ data_reminder });
        } else {
            data_reminder = {
                name_bd: "",
                email_bd: "",
                name_analyst: "",
                email_analyst: ""
            }

            this.setState({ data_reminder });
        }
    }

    onSubmit = async (e) => {
        e.preventDefault();
        let { campaign_id, data_reminder, alert } = this.state;

        const res = await api.post(`campaign/data-reminder/${campaign_id}`, data_reminder);
        const { data } = res;

        if(data.error) {
            alert.message = "Ops, Failed to save data";
            alert.severity = "error";
        } else {
            alert.message = "Success to update data Email BD & Analyst";
            alert.severity = "success";
        }

        this.setState({ alert, openAlert: true });
    }

    onChange = (e, name) => {
        let { data_reminder } = this.state;
        data_reminder[name] = e.target.value;
        this.setState({ data_reminder });
    }
    
    onSelectChange = (e, name, type) => {
        let { data_reminder, list_bd, list_analyst } = this.state;
        let selected_bd, selected_analyst = {}

        if(type === 'bd') {
            selected_bd = list_bd.filter(function(bd) {
                return bd.name == e.target.value;
            });
            
            data_reminder['name_bd'] = selected_bd[0].name;
            data_reminder['email_bd'] = selected_bd[0].email;
        } else if(type === 'analyst') {
            selected_analyst = list_analyst.filter(function(analyst) {
                return analyst.name === e.target.value;
            });

            data_reminder['name_analyst'] = selected_analyst[0].name;
            data_reminder['email_analyst'] = selected_analyst[0].email;
        }

        this.setState({ data_reminder });
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({ openAlert: false });
    };

    
    render() {
        const { data_reminder, openAlert, alert, list_bd, list_analyst } = this.state;
        
        return (
            <>
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={openAlert} autoHideDuration={3000} onClose={this.handleClose}>
                    <Alert onClose={this.handleClose} severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Snackbar>

                {data_reminder ? (
                    <form onSubmit={this.onSubmit}>
                        <Paper className="padding spaceBottom" square>
                            <Grid container spacing={4} className="spaceBig">
                                <Grid item md={6}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>
                                            Select BD
                                        </InputLabel>
                                        <Select
                                            value={
                                                data_reminder.name_bd
                                                ? data_reminder.name_bd
                                                : ""
                                            }
                                            onChange={(e) =>
                                                this.onSelectChange(e, "name_bd", "bd")
                                            }>
                                            <MenuItem value="" disabled>
                                                Please Select One
                                            </MenuItem>
                                            
                                            {list_bd.map(bd => (
                                                <MenuItem value={bd.name} key={Math.random()} disabled={!bd.is_active}>
                                                    {bd.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>
                                            Select Analyst
                                        </InputLabel>
                                        <Select
                                            value={
                                                data_reminder.name_analyst
                                                ? data_reminder.name_analyst
                                                : ""
                                            }
                                            onChange={(e) =>
                                                this.onSelectChange(e, "name_analyst", "analyst")
                                            }>
                                            <MenuItem value="" disabled>
                                                Please Select One
                                            </MenuItem>
                                            
                                            {list_analyst.map(analyst => (
                                                <MenuItem value={analyst.name} key={Math.random()} disabled={!analyst.is_active}>
                                                    {analyst.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label=""
                                        value={data_reminder.email_bd}
                                        fullWidth
                                        margin="normal"
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <TextField
                                        label=""
                                        value={data_reminder.email_analyst}
                                        fullWidth
                                        margin="normal"
                                        disabled
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        className="spaceBig"
                                        color="primary"
                                        style={{width: "150px"}}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </form>
                ):(
                    <div className="loading">
                        <CircularProgress />
                    </div>
                )}
            </>
        )
    }
    
}

export default DataReminder;