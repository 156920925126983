import React, { Component } from 'react'

import { Container } from '@material-ui/core'
import api from 'services/api'
import MaterialTable from "material-table";

class Page extends Component {

   state = {

   }

   componentDidMount() {
      window.scrollTo(0, 0)
   }

   render() {

      return (
         <Container id="get-funded" maxWidth="xl" >
            <MaterialTable
               columns={[
                  { title: 'No', field: 'id' },
                  { title: 'Submited on', field: 'created_at', type: 'datetime' },
                  { title: "Name", field: "name", },
                  { title: "Email", field: "email", },
                  { title: "Phone No", field: "phone_no", },
                  { title: "Company Name", field: "company_name", },
                  { title: "Company Registration Number", field: "company_registration_number", },
                  { title: "Industry", field: "industry", },
                  // { title: "Year Established", field: "year_established", },
                  // { title: "Currency", field: "currency", },
                  { title: "Annual Revenue", field: "annual_revenue", },
                  // { title: "Funding Amount", field: "funding_amount", },
                  // { title: "Funding Period", field: "funding_period", },
                  { title: "Financing Solution", field: "financing_solution", },
                  // { title: "How you know", field: "how_you_know", },
               ]}
               title="Get funded"
               options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100, 200],
                  actionsColumnIndex: -1,
                  filtering: true
               }}
               data={query =>
                  new Promise(async (resolve) => {
                     let field = query.orderBy ? query.orderBy.field : ''
                     let filters = []
                     if (query.filters.length > 0) {
                        query.filters.map(data => {
                           let column = {}
                           let value = []
                           column.field = data.column.field
                           value = data.value
                           filters.push({ 'column': column, 'value': value })
                           return data;
                        })
                        filters = JSON.stringify(filters)
                     }
                     const result = await api.get(`get-funded?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}
                     &orderDirection=${query.orderDirection}&field=${field}&filters=${filters}`)
                     const { data } = result
                     resolve({
                        data: data.data,
                        page: query.page,
                        totalCount: data.total,
                     })
                  })
               }


            />
         </Container>
      )
   }
}

export default Page
